#pr-reviewdisplay {
  .p-w-r {
    .pr-review-display {
      .pr-review {
        .pr-rd-footer {
          .pr-helpful-yes {
            .pr-helpful-count {
              &:before {
                content: 'да(';
              }
            }
          }
          .pr-helpful-no {
            .pr-helpful-count {
              &:before {
                content: 'нет(';
              }
            }
          }
          .pr-rd-flag-review-container {
            margin-right: 12px;
            line-height: 1.5;
          }
        }
      }
    }
  }
}

.product__footer {
  .product-full__review-snippet {
    .pr-snippet-stars-reco-inline {
      .pr-snippet-stars-reco-stars {
        .pr-snippet-read-and-write {
          .pr-snippet-review-count {
            &:before {
              content: 'Читать все ';
            }
          }
        }
        .pr-snippet-stars-container {
          &:before {
            content: 'Общий рейтинг: ';
          }
        }
      }
    }
  }
}

.write_a_review__container {
  #pr-write {
    .pr-war {
      #pr-war-form {
        .form-group {
          .btn-toggle {
            .pr-btn {
              margin-left: 0;
            }
          }
        }
      }
      .pr-submit {
        .pr-control-label {
          display: block;
        }
      }
    }
  }
}
