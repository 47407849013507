#links-panel {
  margin-top: 1em;
}
/* Reducing font size due to favorite panel layout break */
#favorites-panel {
  .product-list {
    .add-to-cart {
      input[type='submit'].btn-mini {
        padding: 0 10px;
        font-size: 11px;
      }
    }
  }
}

#confirmation-page {
  #registration-panel {
    margin-left: 0 !important;
  }
}

.chronopay_message {
  float: none;
  clear: both;
}

.order-summary__tax {
  &-label,
  &-value {
    display: none;
  }
}

.site-header {
  li.site-navigation__item {
    padding: 0 18px;
  }
  li {
    &.site-navigation__item,
    &.site-navigation__more {
      font-size: 14px;
      &.is-dropdown,
      &.is-carousel {
        .site-navigation__dropdown,
        .site-navigation__carousel {
          .site-navigation__submenu-col {
            a,
            h3 {
              font-size: 13px;
            }
          }
        }
        .site-navigation__carousel {
          .block-image-hover__title {
            font-size: 14px;
          }
          .block-image-hover__content {
            font-family: $roboto-condensed-regular-font;
          }
        }
      }
    }
  }
  .site-header__tools {
    .site-my-mac {
      &__contents-title {
        font-weight: bold;
      }
    }
    .site-bag__contents {
      .site-bag {
        &__contents-title {
          font-weight: bold;
        }
      }
    }
  }
  .checkout-navigation__menu {
    li {
      &.site-navigation__item {
        a {
          vertical-align: top;
        }
      }
    }
  }
  .site-navigation__dropdown[menu-sub='1'] {
    li.site-navigation__item {
      padding: 0 14px !important;
      width: auto;
    }
  }
}

.site-footer--top {
  .grid-container--6 .grid--item {
    &:nth-of-type(6n + 1) {
      width: 20%;
    }
    &:nth-of-type(6n + 2) {
      width: 20%;
      @include swap_direction(margin, 0 -100% 0.5em 20%);
    }
    &:nth-of-type(6n + 3) {
      width: 20%;
      @include swap_direction(margin, 0 -100% 0.5em 40%);
    }
    &:nth-of-type(6n + 4) {
      width: 20%;
      @include swap_direction(margin, 0 -100% 0.5em 60%);
    }
    &:nth-of-type(6n + 5) {
      width: 20%;
      @include swap_direction(margin, 0 -100% 0.5em 80%);
    }
  }
}

.site-container {
  min-height: 640px;
  .culture-detail {
    &__article-content {
      .culture__social-links {
        width: 6.5em;
        @include swap_direction(padding, 0 0 10px 0);
      }
    }
  }
  .artistry-video-player-wrapper {
    .product--teaser {
      &.prod_inv_status-2,
      &.prod_inv_status-3 {
        .product__footer {
          .product__price {
            margin: 0;
          }
        }
      }
      .product__detail {
        h3.product__name {
          font-size: 1.3rem;
        }
        .product__footer {
          .product__price {
            margin: 0;
          }
        }
      }
    }
  }
  .page--spp__product {
    .product__footer {
      .product__share-wrapper {
        .product__social-links {
          width: 7.5em;
          @include swap_direction(padding, 0 0 4px 2em);
        }
      }
      .product__price {
        font-family: $roboto-condensed-bold-font;
      }
    }
    @include breakpoint($bp--xlarge-down) {
      .shade-picker__wrapper {
        .shade-picker__tools-heading {
          padding-top: 12px;
        }
      }
    }
    .view-all-shades {
      #sku_count {
        display: none;
      }
    }
  }
  .product__link-to-spp {
    #sku_count {
      display: none;
    }
  }
  .grid--mpp__item {
    .product {
      &__link-custom {
        display: none;
      }
    }
    .product--teaser {
      .product__header {
        .product__name {
          font-size: 15px;
          line-height: 1.1;
        }
      }
      .product__footer {
        bottom: 10px;
        .product__inventory-status {
          a.notify_me {
            @include breakpoint($bp--xlarge-down) {
              font-size: 12px;
            }
            @include breakpoint($bp--large-down) {
              width: auto;
              line-height: 30px;
              font-size: 13px;
              @include swap_direction(padding, 0 15px);
            }
          }
        }
        .product__add-to-bag {
          @include breakpoint($bp--xlarge-down) {
            font-size: 13px;
          }
        }
        .product__link-to-spp {
          @include breakpoint($bp--xlarge-down) {
            font-size: 13px;
          }
        }
        .product__price {
          font-family: $roboto-condensed-bold-font;
          line-height: 30px;
          font-size: 16px;
          @include breakpoint($bp--xlarge-down) {
            font-size: 14px;
          }
        }
      }
    }
    .prod_inv_status-7 {
      .product__footer {
        .sold-out {
          height: 30px;
          line-height: 30px;
        }
      }
    }
    .prod_inv_status-5 {
      .product__footer {
        .product_content_fav_mobile {
          min-height: 30px;
        }
        .inactive {
          height: 30px;
          line-height: 30px;
        }
      }
    }
  }
  .prod_inv_status-3 {
    .product__footer {
      a.notify_me {
        display: block !important;
      }
    }
  }
  .search-results {
    .search-filter__trigger {
      @media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
        width: 30%;
      }
    }
  }
  .site-banner__sticky-fix {
    @include breakpoint($bp--xlarge-down) {
      .sec-nav__sections {
        max-width: 50%;
        .sec-nav__item {
          max-width: 255px;
          .sec-nav__link {
            margin-top: 4px;
          }
        }
      }
    }
  }
  .multi-use-tout--align-raised {
    .multi-use-tout__caption-inner-inner {
      left: 0;
      width: 100%;
      text-align: center;
      .multi-use-tout__title {
        font-size: 6.5rem;
      }
      .multi-use-tout__body {
        width: 50%;
        @include swap_direction(margin, 0 auto);
      }
    }
  }
  .ambient-header--raised {
    .multi-use-tout__title {
      font-size: 6.5rem;
    }
  }
  .multi-use-tout--align-center {
    .multi-use-tout__caption-inner-inner {
      width: 100%;
      .multi-use-tout__body {
        text-align: center;
        width: 50%;
        @include swap_direction(margin, 0 auto);
      }
    }
  }
  .contact-us-page {
    .contact-nav {
      li {
        @include breakpoint($bp--xlarge-up) {
          &:nth-of-type(4n + 2) {
            width: 27%;
          }
          &:nth-of-type(4n + 3) {
            @include swap_direction(margin, -1px -100% 0 52%);
          }
        }
      }
    }
  }
  #product-page-livechat-hitarea {
    width: 120px;
  }
}

.layout--artistry-artist {
  .artist--products__list {
    .product__detail {
      .product__header {
        .product__name {
          @include swap_direction(padding, 0 90px 0 0);
          word-wrap: break-word;
        }
      }
      .product__footer {
        .product__add-to-bag {
          @include breakpoint($bp--xlarge-down) {
            font-size: 13px;
          }
        }
        a.product__link-to-spp {
          @include breakpoint($bp--xlarge-down) {
            font-size: 13px;
          }
        }
        .product__price {
          font-family: $roboto-condensed-bold-font;
          line-height: 30px;
          font-size: 16px;
          @include breakpoint($bp--xlarge-down) {
            font-size: 14px;
          }
        }
      }
    }
  }
  .artist--interview {
    h2,
    h3 {
      font-weight: bold;
    }
  }
}

.site-navigation__dropdown {
  .site-navigation__menu {
    h3 {
      font-weight: bold;
    }
  }
}

.store-locator {
  .store-locator-landing__form {
    .store-locator-landing__title {
      font-weight: bold;
    }
  }
}

h4.store-heading {
  font-weight: bold;
}

#email_sms_signup {
  #form--email_sms_signup--field--MOBILE_NUMBER {
    display: none;
  }
}

@include breakpoint($bp--xlarge-up) {
  .submenu {
    .submenu__header {
      width: 30%;
      h3 {
        font-size: 26px;
      }
    }
  }
}

.culture-landing__item {
  height: 560px;
  @include breakpoint($bp--xlarge-down) {
    height: 540px;
  }
  .culture-landing__teaser-wrap {
    height: 300px;
    @include breakpoint($bp--xlarge-down) {
      height: 350px;
    }
    .culture-landing__teaser {
      &-title {
        @include breakpoint($bp--largest-up) {
          width: 100%;
        }
      }
    }
  }
}

.tiny-waitlist-overlay {
  .field {
    .align-r {
      .input-btn {
        width: auto;
        @include swap_direction(padding, 11px);
      }
    }
  }
}

.store_landing_only {
  .tout-block-landscape__body-above {
    .tout-block-landscape__header-image {
      background-image: url('/media/export/cms/locator/makeup-services_ru.png');
    }
  }
}

#csr_header_holder {
  z-index: 99999;
}

@include breakpoint($bp--xlarge-down) {
  .section-book-appointment.top-header {
    .appt-book-page-header {
      header.submenu__header {
        width: 33%;
      }
    }
  }
}

#appt-book-my-appointments-overlay {
  .overlay-content-container {
    .canceled {
      .book-new-btn {
        width: 330px;
      }
    }
  }
}

.appt-book {
  #appointment-book-sections {
    .my-appointments {
      .appointment-container {
        .appointment-details {
          .date-time {
            .appt-date-cal {
              background: transparent
                url(/media/export/cms/makeup_services/appointment_booking/appointments/cal_date_picker_icon.png)
                no-repeat 12em 0.25em/12px 12px;
            }
          }
        }
      }
    }
    .appt-book-location-selector {
      .form-container {
        .ui-select {
          padding-bottom: 20px;
        }
        .location-submit {
          margin: 40px auto 110px;
        }
      }
    }
    .appointment-select {
      .appointments-container {
        .appointment-select {
          .calendar-head {
            .cal-head-controls {
              .cal-controls.previous {
                width: 30%;
              }
              .cal-controls.day-time {
                width: 40%;
              }
              .cal-controls.next {
                width: 30%;
              }
            }
          }
        }
      }
    }
  }
  #services {
    .minutes,
    .price {
      text-transform: uppercase !important;
    }
  }
  .confirmation {
    .confirmation-container {
      .date-time {
        .appt-date-cal {
          background-position: 11.5em 0.25em !important;
        }
      }
    }
  }
}

@media (min-width: 80.0625em) {
  .site-header li.site-navigation__item,
  .site-header li.site-navigation__more {
    padding: 0 20px !important;
  }
}

.site-header {
  .field-collection-slides {
    li.site-navigation__item {
      a {
        font-size: 15px !important;
      }
    }
  }
}

@media (min-width: 1274px) {
  .site-header {
    li.site-navigation__item {
      display: block !important;
    }
    li.site-navigation__item.site-navigation__more {
      display: none !important;
    }
  }
}

.tiny-waitlist-overlay {
  .field {
    .email_promo {
      padding-top: 6px;
    }
  }
}

.appt-book {
  .confirmation {
    .confirmation-container {
      .date-time {
        .appt-date-cal {
          background-position: 12.5em 0.25em !important;
        }
      }
    }
  }
}

.click_and_collect_map {
  .map-container {
    font-family: roboto-condensed-regular, Helvetica, Arial, Sans-Serif !important;
  }
}

.page--spp__product {
  .product__detail {
    .shipping-cost-widget {
      .shipping-cost {
        p:nth-of-type(2) {
          span:nth-child(1) {
            width: 140%;
            float: left;
            margin-bottom: 5px;
          }
        }
      }
    }
    a.shipping_calc {
      font-size: 1rem;
      font-family: 'roboto-mono_regular', Helvetica, Arial, Sans-Serif;
      line-height: 1.5;
      cursor: pointer;
      display: inline-block;
      @include swap_direction(margin, 10px 0 0 22px);
    }
  }
}

#ship-calc-overlay {
  padding: 25px;
  .form_element {
    label {
      display: block;
    }
  }
  .country-container {
    float: left;
    width: 100%;
    margin-bottom: 10px;
    input,
    select {
      width: 100%;
      float: left;
    }
  }
  .calculation-result {
    .shipping-type {
      font-weight: bold;
    }
  }
  .submit_popup {
    padding-top: 15px;
  }
  .close-popup {
    padding: 6px 30px;
    background: $color--black;
    margin-left: 15px;
    font-size: 14px;
    cursor: pointer;
    font-family: $roboto-condensed-bold-font;
    text-decoration: none;
    color: $color--white;
    float: right;
  }
  .input-btn {
    border: none;
    line-height: 18px;
    padding: 8px 30px;
    font-family: $roboto-condensed-bold-font;
    font-size: 14px;
    background: $color--black;
    cursor: pointer;
    text-decoration: none;
    color: $color--white;
  }
  .missing_region,
  .missing_city {
    color: $color--red !important;
  }
}

.shipping-cost-widget {
  @include swap_direction(margin, 10px 0 -18px 0);
  .delivery_region {
    margin-top: -10px;
  }
  .shipping-price {
    margin-right: 5px;
  }
  .shipping-title {
    font-family: $roboto-condensed-bold-font;
    text-transform: uppercase;
    line-height: 18px;
    margin-bottom: 5px;
  }
  .country-container {
    display: block;
    margin-bottom: -11px;
  }
  .city_region {
    text-transform: uppercase;
  }
}

#shipping_calculator {
  .calculation-result {
    clear: both;
    display: block;
    width: 100%;
    margin: 7px 3px;
  }
}

.page-product {
  .page--spp__product {
    .product__detail {
      a.shipping_calc {
        margin: 10px 0 0;
      }
      .product__shipping {
        text-align: left !important;
        .display_non_pro_user {
          display: none;
        }
      }
    }
  }
}

.shipping-cost-widget {
  .delivery_region {
    margin-top: -15px;
    line-height: 26px;
  }
  .country-container {
    line-height: 26px;
  }
}

.email-and-sms-promotions {
  .terms-container {
    .picker {
      .picker-label {
        line-height: 19px;
        float: none;
      }
    }
  }
}

.js-site-bag-contents-products {
  height: 220px !important;
}
