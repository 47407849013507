.checkout__content {
  .order-summary__content {
    .value {
      width: 13.3333% !important;
      margin-#{$ldirection}: 78.3333%;
    }
  }
  #checkout_complete {
    .picker-radio {
      float: none;
    }
  }
}

.checkout {
  .cart-item__qty-label {
    display: none !important;
  }
  &__sidebar {
    .checkout__panel-content {
      .cart-item {
        &__qty {
          @include swap_direction(margin, 0 0 0 50%);
        }
        &__total {
          width: 35%;
        }
      }
    }
    #past-purchases-pane {
      .past-purchases-panel__title {
        font-size: 22px;
      }
      .btn-mini {
        font-size: 11px;
      }
    }
    .tab-switcher {
      .tab-pane {
        .checkout__panel-title {
          @include breakpoint($bp--xlarge-down) {
            @include breakpoint($bp--large-up) {
              font-size: 17px !important;
            }
          }
        }
      }
    }
  }
  &__content {
    .cart-header__title--total {
      margin-#{$ldirection}: 83.3333%;
    }
    .cart-item__total {
      margin-#{$ldirection}: 78.3333%;
    }
    .cart-header__title--qty {
      margin-#{$ldirection}: 64.3333%;
    }
    #shipping-panel {
      .ship-method-home-group,
      .ship-method-collection-group {
        label {
          display: none;
        }
      }
    }
  }
  .checkout__sidebar {
    .order-summary__content {
      .order-summary {
        &__total-label {
          color: $color--black;
          background: $color--gray--white;
        }
        &__total-value {
          padding-right: 0em;
          background: $color--gray--white;
        }
      }
    }
  }
}

#viewcart-panel {
  .cart-item {
    .cart-item__total {
      width: 13.3333%;
    }
    .product__price {
      font-family: $roboto-condensed-bold-font;
    }
  }
  .cart-header__title {
    font-size: 21px;
  }
}

#checkout_shipping_panel {
  .select-address {
    .select2-container {
      width: 72% !important;
    }
  }
}

#confirmation-panel {
  .print-buttons {
    margin-top: 25px;
  }
}

.delivery_method {
  label {
    display: block;
  }
}

@include breakpoint($bp--xlarge-down) {
  @include breakpoint($bp--large-up) {
    .checkout__content {
      .cart-item__total {
        margin-left: 80.3333%;
      }
      .order-summary__content {
        .value {
          margin-left: 80.3333%;
        }
      }
      #bottom-viewcart-buttons {
        .continue-buttons {
          padding-right: 9%;
        }
      }
    }
  }
}

#checkout_shipmethod {
  padding-#{$ldirection}: 0;
  padding-#{$rdirection}: 0;
}

#offer-code-panel p.note {
  display: none;
}

.offer-code-overlay .offer-code__content {
  margin: 40px 20px 0;
}
