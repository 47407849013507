.top-header {
  .site-header {
    .site-header {
      &__tools {
        .site-my-mac {
          height: 60px;
          padding: 0 16.5%;
          position: relative;
          vertical-align: top;
          &__link-header {
            display: block;
            font-size: 10px;
            height: 60px;
            line-height: normal;
            padding: 15px 20px 0 20px;
            text-align: left;
            text-transform: uppercase;
            white-space: nowrap;
          }
        }
      }
      &__menu {
        width: 864px;
        margin-left: 172px;
      }
    }
    &__fixed-wrapper {
      .site-header {
        &__suffix {
          .site-header {
            &__tools {
              .site-bag {
                height: 50px;
              }
            }
          }
        }
      }
    }
  }
}

.random-gift-picker {
  $self: &;
  &__overlay-inner-content-success {
    #{$self} {
      &__overlay-terms {
        top: auto;
        #{$self}__see-details {
          top: 30px;
        }
      }
    }
  }
}

.section-vivaglam-timeline {
  .site-container {
    background: transparent url('/media/export/cms/collections/vg_timeline_2021/vg_timeline_2021-11_bg_dt.jpg') no-repeat center top;
    max-width: 1280px;
    background-size: 100%;
    margin: 0 auto;
  }
}
