$lookup_border_color: #999;
$lookup_background_color: #d8d8d8;

.profile-info__fieldset {
  div.form_birthday {
    width: 100% !important;
  }
}

.ac_list {
  border: 1px solid $lookup_border_color;
  overflow: hidden;
  padding: 0;
  width: 100%;
  z-index: 20;
  ul {
    height: auto;
    list-style: outside none none;
    margin: 0;
    max-height: 300px;
    overflow-x: hidden;
    overflow-y: auto;
    padding: 0;
    width: 100%;
    li {
      border-top: 1px solid $lookup_border_color;
      cursor: pointer;
      display: block;
      margin: 0;
      overflow: hidden;
      padding: 2px 5px;
      position: relative;
      &:first-child {
        border-top: none;
      }
    }
    .ac_hover {
      background-color: $lookup_background_color;
    }
  }
}

.order-details-page {
  .product-header {
    .price {
      margin-left: 47% !important;
    }
    .quantity {
      margin-left: 67% !important;
    }
    .total {
      margin-left: 65% !important;
    }
  }
  .cart-item {
    &__price {
      margin-left: 47% !important;
    }
    &__qty {
      margin-left: 67% !important;
    }
    &__total {
      margin-left: 92% !important;
    }
    &__desc {
      margin-left: 21% !important;
    }
  }
}

.order-products {
  .tracking {
    display: none;
  }
}

.past-purchases {
  .grid--mpp {
    .product__header {
      .product-item__sub-line {
        @include swap_direction(margin, 6px 80px 15px 0);
      }
    }
    .product--teaser {
      margin-bottom: 30px;
      .product__footer {
        .product-item__out-of-stock {
          margin-top: 15px;
        }
        .product-item__sold-out {
          height: 0;
        }
      }
    }
  }
}

.tiny-waitlist-overlay {
  #waitlist {
    h2.waitlist_header {
      font-size: 20px;
      line-height: 25px;
    }
    .field {
      width: 100%;
      .email_input {
        float: #{$ldirection};
        width: 63%;
      }
      .align-r {
        float: #{$ldirection};
        @include swap_direction(margin, 0 0 0 10px);
        width: 34%;
      }
      input[type='text'] {
        width: 100%;
      }
    }
  }
  .waitlist_thankyou_message {
    margin-top: 10px;
  }
}

#colorbox {
  .profile-pic-upload {
    &__form-wrapper {
      .btn-submit {
        clear: both;
      }
    }
  }
}

.past-purchases,
.favorites-page__content {
  .product--teaser {
    min-height: 570px;
    .product__header {
      .product__name {
        line-height: 20px;
      }
      z-index: 1;
    }
    .product__footer {
      bottom: 10px;
      .product__price {
        font-family: $roboto-condensed-bold-font;
        line-height: 30px;
      }
    }
  }
}

.favorites-page__content {
  .product--teaser {
    min-height: 550px;
  }
}

.past-purchases__item {
  .product--teaser {
    .product__add {
      .product-item__out-of-stock {
        text-align: right;
      }
    }
  }
}

@if $password_strengthen {
  .sign-in-container {
    .password-field {
      &__info {
        @include breakpoint($bp--medium-up) {
          bottom: -15px;
        }
      }
    }
  }
}
